// extracted by css-extract-rspack-plugin
export default {"loader":"src-components-Layout-Loader-style-module__loader-s0iscE","fullScreen":"src-components-Layout-Loader-style-module__fullScreen-e5VbxV","hidden":"src-components-Layout-Loader-style-module__hidden-IonzIk"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"loader\":\"src-components-Layout-Loader-style-module__loader-s0iscE\",\"fullScreen\":\"src-components-Layout-Loader-style-module__fullScreen-e5VbxV\",\"hidden\":\"src-components-Layout-Loader-style-module__hidden-IonzIk\"}";
        // 1731656930446
        var cssReload = require("../../../../node_modules/.pnpm/@rspack+core@1.1.1_@swc+helpers@0.5.15/node_modules/@rspack/core/dist/cssExtractHmr.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  